html,
body {
  background: #fff;
  font-size: 14px;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
  color: #444;
  font-weight: normal;
  min-width: 320px;
}
body {
  position: relative;
  line-height: 1.42857143;
  overflow-x: hidden;
  margin: 0;
}
h1 {
  margin: 0;
  font-size: 36px;
}
h2 {
  margin: 0;
  font-size: 24px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.img-responsive {
  max-width: 100%;
  width: 100%;
}
.text-danger {
  color: #dc3545 !important;
}
.tac {
  text-align: center;
}
.icon {
  display: inline-block;
}
h1 > .icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 5px;
  background: #FF9816;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  padding: 3px 32px;
  text-decoration: none;
  text-align: center;
  user-select: none;
  white-space: nowrap;
}
.btn .fa {
  margin-right: 5px;
}
.btn.btn-lg {
  padding: 12px 26px;
  border-radius: 10px;
  font-size: 22px;
}
.btn.btn-grey {
  background: #a8a8a8;
}
.btn.btn-grey:focus,
.btn.btn-grey:active,
.btn.btn-grey:hover {
  background: #ccc;
}
.btn.btn--login {
  background-color: transparent;
  color: #55bf64;
  font-size: 16px;
  border: 1px solid #55bf64;
}
.btn.btn--login:focus,
.btn.btn--login:active,
.btn.btn--login:hover {
  background-color: #55bf64;
  color: #fff;
}
.btn:focus,
.btn:active,
.btn:hover {
  text-decoration: none;
  background: #55bf64;
}
.flex-right {
  margin-left: auto;
}
.ta {
  text-align: center;
}
.main {
  position: relative;
  margin: 0 auto;
  width: 1024px;
}
.header {
  height: 90px;
  border-bottom: 2px solid #efefef;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}
.logo img {
  display: block;
  width: 100px;
  float: left;
}
.main-menu {
  float: right;
  margin-top: 3px;
}
.main-menu a {
  color: #444;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  margin-left: 10px;
}
.main-menu a:active,
.main-menu a:hover,
.main-menu a:focus {
  color: #444;
  text-decoration: underline;
}
.menu-mobile {
  display: none;
}
.section1 {
  padding: 65px 0;
}
.section1-left {
  float: left;
  line-height: 36px;
}
.section1-right {
  margin-left: 350px;
  text-align: right;
}
.section1-screen-image {
  margin-top: 58px;
}
.section2 {
  padding: 68px 10px 0;
  font-size: 16px;
  height: 300px;
}
.section2-list {
  float: left;
  width: 265px;
}
.section2-list-item {
  padding-left: 20px;
  position: relative;
  margin-bottom: 6px;
  cursor: pointer;
}
.section2-list-item:before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
  background: #e8e8e8;
}
.section2-list-item.active:before {
  background: #3ba152;
}
.section2-text {
  margin-left: 278px;
  line-height: 26px;
}
.section2-text-item {
  display: none;
}
.section2-text-item.active {
  display: block;
}
.section2-text-item-head {
  font-weight: bold;
  font-size: 19px;
  padding-bottom: 14px;
}
.section3 {
  padding: 14px 0 0 30px;
}
.section3-head {
  font-size: 23px;
  margin-bottom: 25px;
}
.section3-flag {
  float: left;
  width: 248px;
  padding-left: 44px;
  height: 30px;
  font-size: 19px;
  padding-top: 2px;
  background-size: 29px;
  background-position: 4px 0;
  background-repeat: no-repeat;
  margin-bottom: 18px;
}
.section3-flag:nth-child(3n) {
  width: 190px;
}
.flag-us {
  background-image: url(/images/us.svg);
}
.flag-de {
  background-image: url(/images/de.svg);
}
.flag-es {
  background-image: url(/images/es.svg);
}
.flag-mx {
  background-image: url(/images/mx.svg);
}
.flag-it {
  background-image: url(/images/it.svg);
}
.flag-uk {
  background-image: url(/images/uk.svg);
}
.flag-ca {
  background-image: url(/images/ca.svg);
}
.flag-fr {
  background-image: url(/images/fr.svg);
}
.flag-in {
  background-image: url(/images/in.svg);
}
.pricing {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.section4 {
  padding-top: 100px;
}
.section4-extension {
  padding: 30px;
  width: 30%;
  border: 2px solid transparent;
}
.section4-extension .btn {
  background: transparent;
  border: 1px solid #FF9816;
  color: #FF9816;
}
.section4-extension .btn:focus,
.section4-extension .btn:active,
.section4-extension .btn:hover {
  border-color: #55bf64;
  background: #55bf64;
  color: #fff;
}
.section4-extension:hover {
  border-color: #bae3cc;
}
.section4-head {
  font-size: 23px;
}
.section4-head strong {
  display: inline-block;
  font-size: 25px;
  float: right;
  margin-top: -4px;
}
.section4-features {
  margin-top: 13px;
  margin-bottom: 17px;
  font-size: 16px;
}
.section4-features-item {
  margin-bottom: 3px;
}
.clear {
  clear: both;
}
.section5 {
  padding: 82px 0px 0;
}
.section5-head {
  font-size: 23px;
  margin-bottom: 37px;
}
.section5-item {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 16px;
}
.section5-item-head {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 11px;
}
.section6 {
  padding: 72px 0px 0;
}
.section6-head {
  font-size: 23px;
}
.bx-wrapper {
  margin-top: 51px;
}
.img-circle {
  border-radius: 50%;
}
.slide-content-left {
  width: 100px;
  height: 124px;
  float: left;
}
.slide-content-right {
  margin-left: 164px;
  font-size: 16px;
}
.slide-user-name {
  font-weight: bold;
  margin-top: 15px;
}
.slide-user-info {
  color: #ababab;
}
.footer {
  padding: 27px 0;
  margin: 171px 30px 0;
  border-top: 1px solid #9d9d9d;
}
.footer-left {
  float: left;
  width: 50%;
}
.footer-right {
  margin-left: 50%;
  text-align: right;
}
.footer-right a {
  color: #444;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
}
.footer-right a:active,
.footer-right a:hover,
.footer-right a:focus {
  color: #444;
  text-decoration: underline;
}
.footer-mobile {
  display: none;
}
@media (max-width: 800px) {
  .main {
    width: 100%;
    min-width: 320px;
    overflow: hidden;
  }
  .main-menu {
    display: none;
  }
  .menu-mobile {
    display: block;
    width: 40px;
    float: right;
  }
  .section1 {
    padding: 20px 10px 0;
  }
  .section1-left {
    float: none;
    font-size: 18px;
  }
  .section1-right {
    margin-left: 0;
    text-align: center;
    padding-top: 15px;
  }
  .section1-screen-image {
    margin-top: 24px;
  }
  .section2 {
    padding-top: 24px;
    height: auto;
  }
  .section2-list {
    display: none;
  }
  .section2-text {
    margin-left: 0;
  }
  .section2-text-item {
    display: block;
    margin-bottom: 10px;
  }
  .section3 {
    padding: 24px 10px 0;
  }
  .section3-flag {
    width: 45%;
  }
  .section3-flag:nth-child(3n) {
    width: 45%;
  }
  .section4 {
    padding: 24px 10px 0;
  }
  .section4-head {
    font-size: 18px;
  }
  .section4-extension-pro {
    float: none;
    width: 100%;
    padding: 17px 17px 23px;
  }
  .section4-extension-lite {
    width: 100%;
    margin-left: 0;
    padding: 17px 17px 23px;
  }
  .section5 {
    padding: 24px 10px 0;
  }
  .section6 {
    padding: 24px 10px 0;
  }
  .bx-wrapper {
    margin-top: 14px;
  }
  .slide-content-left {
    width: 64px;
    height: 64px;
  }
  .slide-content-right {
    margin-left: 80px;
    padding-right: 10px;
  }
  .footer {
    margin-top: 80px;
    padding-top: 10px;
  }
  .footer-left {
    display: none;
  }
  .footer-right {
    margin-left: 0;
    text-align: center;
  }
  .footer-right a {
    margin: 5px 0;
    display: block;
  }
  .footer-mobile {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  .menuToggle {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    z-index: 101;
    cursor: pointer;
    user-select: none;
  }
  .menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #444;
    border-radius: 3px;
    z-index: 1;
  }
  .menuToggle span:first-child {
    transform-origin: 0% 0%;
  }
  .menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  #menu {
    display: block;
    position: fixed;
    width: 50%;
    min-width: 200px;
    height: 100%;
    margin: 0;
    padding: 3px;
    padding-top: 125px;
    left: 0;
    top: 0;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    z-index: 100;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }
  #menu.checked {
    transform: none;
  }
}
.mobile-menu {
  background: #fafafa none repeat scroll 0 0;
  border-right: 1px solid #e0e0e0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #424242;
  height: 100%;
  left: 0;
  max-height: 100%;
  overflow-x: visible;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transform: translateX(-250px);
  transform-style: preserve-3d;
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 240px;
  will-change: transform;
  z-index: 1050;
}
.mobile-menu:active {
  border: none;
}
.mobile-menu.active {
  transform: translateX(0);
}
.mobile-menu-backdrop {
  opacity: 0.5;
  display: none;
  position: fixed;
  z-index: 1040;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mobile-menu-items {
  padding: 10px 0;
  overflow-y: auto;
  list-style: none;
}
.mobile-menu-items a {
  color: #444;
  text-decoration: none;
  display: block;
  padding: 16px 40px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.mobile-menu-items a:active,
.mobile-menu-items a:hover,
.mobile-menu-items a:focus {
  color: #444;
  text-decoration: underline;
}
.mobile-logo {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 58px;
}
.checked {
  color: orange;
}
.fa {
  font-size: 22px;
}
.center1 {
  padding-right: 15px;
  padding-top: 5px;
}
.mt5 {
  margin-top: 5px;
}
.mr10 {
  margin-right: 10px;
}
.username {
  font-size: 16px;
}
.username .fa {
  font-size: 16px;
  margin-right: 5px;
}
